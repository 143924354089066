import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  createContext,
  useContext,
  createRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import icons from "../../icons";
import useView from "../../utils/useView";
import Card from "../Card";
import Error from "../Error";
import Loader from "../Loader";
import Layout from "../parts/Layout";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import useViewPage from "../../utils/useViewPage";
import { Pagination } from "@zendeskgarden/react-pagination";
import { Row, Col } from "styled-bootstrap-grid";
import {
  Field as FormField,
  Select as FormSelect,
  Label,
  MediaInput,
} from "@zendeskgarden/react-forms";
import formatDateTime from "../../utils/formatDateTime";
import formatDate from "../../utils/formatDate";
import Button from "../Button";
import {
  Dropdown,
  Trigger,
  Multiselect,
  Field,
  Menu,
  Item,
  ItemMeta,
  Separator,
} from "@zendeskgarden/react-dropdowns";
import { RiFilterFill, RiFilterOffFill } from "react-icons/ri";
import { BiChevronDown, BiDownload, BiLoader } from "react-icons/bi";
import { Tag } from "@zendeskgarden/react-tags";
import Breadcrumbs from "../parts/Breadcrumbs";
import DOMPurify from "dompurify";
import callAction from "../../utils/callAction";
import { useSWRConfig } from "swr";
import { useToast } from "@zendeskgarden/react-notifications";
import Notification from "../Notification";
import LoaderOverlay from "../LoaderOverlay";
import domPurifyWithHooks from "../../utils/adjustLinks";
import callViewExportFile from "../../utils/callViewExcel";
import { FaFileCsv, FaFileExcel } from "react-icons/fa";
import useViews from "../../utils/useViews";
import { VOLVO } from "../../constants";
import config from "../../config";
import styled from "styled-components";
import { IoCheckmarkSharp, IoClose } from "react-icons/io5";
import { AiOutlineFullscreen, AiOutlineFullscreenExit } from "react-icons/ai";
import { AppContext } from "../../context/AppContext";
import { saveAs } from "file-saver";
import makeBlobFromCSV from "../../utils/makeBlobFromCSV";
import { ReactComponent as StartIcon } from "@zendeskgarden/svg-icons/src/16/search-stroke.svg";
import { ReactComponent as EndIcon } from "@zendeskgarden/svg-icons/src/16/x-stroke.svg";

const sortKeysByValue = (mapping) => {
  const entries = Object.entries(mapping);
  const sorted = entries.sort(
    ([key1, val1], [key2, val2]) => val1.toLowerCase() > val2.toLowerCase()
  );
  return sorted.map((ent) => ent[0]);
};

const SToolbarTop = styled.div`
  padding: ${(props) => (props.fullScreen ? "8px 8px 0 8px" : "8px 0 0 0")};
  .toolbar-item {
    margin-bottom: 8px;
  }
`;

const GridContext = createContext({});

const MultiSelectFilter = ({
  options = [],
  name = "",
  colId,
  colFilters,
  onFilterChanged,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [matchingOptions, setMatchingOptions] = useState(options);

  const filterMatchingOptionsRef = useRef((value) => {
    const matchedOptions = options.filter((option) => {
      return (
        option.trim().toLowerCase().indexOf(value.trim().toLowerCase()) !== -1
      );
    });

    setMatchingOptions(matchedOptions);
    setIsLoading(false);
  });

  useEffect(() => {
    setIsLoading(true);
    filterMatchingOptionsRef.current(inputValue);
  }, [inputValue]);

  const onSelect = (items) => {
    onFilterChanged({ colId, selectedItems: items });
  };

  const onClear = (e) => {
    e.stopPropagation();
    onFilterChanged({ colId, selectedItems: [] });
  };

  const renderOptions = () => {
    if (isLoading) {
      return <Item disabled>...</Item>;
    }

    if (matchingOptions.length === 0) {
      return <Item disabled> - </Item>;
    }

    return matchingOptions.map((option) => (
      <Item key={option} value={option}>
        <span>{option}</span>
      </Item>
    ));
  };

  return (
    <Dropdown
      inputValue={inputValue}
      selectedItems={colFilters}
      onSelect={onSelect}
      downshiftProps={{ defaultHighlightedIndex: 0 }}
      onInputValueChange={(value) => setInputValue(value)}
    >
      <Field>
        <Multiselect
          disabled={disabled}
          isCompact
          renderItem={({ value, removeValue }) => (
            <Tag>
              <span>{value}</span>
              <Tag.Close onClick={() => removeValue()} />
            </Tag>
          )}
          placeholder={name}
          start={
            colFilters.length > 0 ? (
              <RiFilterOffFill onClick={onClear} />
            ) : (
              <RiFilterFill style={{ color: "#aaa" }} />
            )
          }
        />
      </Field>
      <Menu isCompact>{renderOptions()}</Menu>
    </Dropdown>
  );
};

const HTMLCellRenderer = ({ value }) => {
  return (
    <div
      className="html-inner"
      dangerouslySetInnerHTML={
        !!value
          ? {
              __html: DOMPurify.sanitize(value, {
                USE_PROFILES: { html: true },
              }),
            }
          : null
      }
    />
  );
};

const SelectDeselectAllHeader = ({
  selectAllActionData,
  resetActionData,
  checked,
  setChecked,
}) => {
  const { t } = useTranslation("common");
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      selectAllActionData();
      setChecked(true);
    } else {
      resetActionData();
      setChecked(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={handleSelectAll}
        style={{
          cursor: "pointer",
          width: "16px",
          height: "16px",
          marginLeft: "-4.5px",
        }}
        title={t("ui.select-deselect-all-tooltip")}
      />
    </div>
  );
};

const ActionCellRenderer = ({ value, values, colDef, node }) => {
  const valueStr = value + "";
  const rowId = node?.data?.[0];

  const { actionData, setActionData } = useContext(GridContext);

  const actionDataRow = actionData?.filter(
    (item) => item?.rowId === rowId
  )?.[0];
  const editedValue = actionDataRow?.optionKey || "";

  const withLabels = values.map((val) => ({
    value: val,
    label: colDef?.refData?.[val],
  }));

  const hasNewValue = !!actionDataRow && editedValue !== valueStr;

  const onSelectOption = (e) => {
    const isNewValue = e.target.value !== valueStr;

    setActionData((curr) => {
      const otherRows = curr.filter((item) => item?.rowId !== rowId);
      const newActionData = isNewValue
        ? [
            ...otherRows,
            {
              rowId: rowId,
              optionKey: e.target.value,
            },
          ]
        : otherRows;
      return newActionData;
    });

    // select row when value changed, deselect otherwise
    node.setSelected(isNewValue);
  };

  const undoEdit = () => {
    setActionData((curr) => [
      ...curr.filter((item) => item?.rowId + "" !== rowId + ""),
    ]);
    node.setSelected(false);
  };

  return (
    <div
      className={`action-options-select ${hasNewValue ? "has-new-value" : ""}`}
    >
      <FormField>
        <Label hidden>Select value</Label>
        <FormSelect
          isBare
          value={hasNewValue ? editedValue : valueStr || ""}
          onChange={onSelectOption}
          disabled={!node.isSelected()}
        >
          <option value=""></option>
          {withLabels.map(({ value: optionValue, label: optionLabel }) => (
            <option
              value={optionValue}
              key={`${optionValue}${rowId}`}
              className={
                optionValue === valueStr
                  ? "option-initial"
                  : optionValue === editedValue
                  ? "option-selected"
                  : ""
              }
            >
              {optionLabel}
            </option>
          ))}
        </FormSelect>
      </FormField>

      <BiChevronDown className="chevron-icon" />
      <Button link className={`cancel-btn`} onClick={undoEdit}>
        <IoClose className="cancel-icon" />
      </Button>
    </div>
  );
};

const SGridWrap = styled.div`
  min-height: 471px;
  max-height: calc(100vh - 114px);
  height: 471px;
  transition: height 1s;
  &.grid-container-fullscreen {
    min-height: calc(100vh - 145px);
    max-height: calc(100vh - 145px);
    height: calc(100vh - 145px);
  }
  .ag-cell:not(.checkbox-cell) {
    padding-top: 9px;
    padding-bottom: 9px;
    min-height: 42px;
    .html-inner {
      line-height: 1.6;
    }
  }
  &.disable-row-selection {
    .ag-checkbox .ag-checkbox-input-wrapper:not(.ag-checked) {
      visibility: hidden;
    }
    .chevron-icon {
      opacity: 0.2;
    }
    .ag-row-selected {
      .chevron-icon {
        opacity: 0.7;
      }
    }
  }
  &.no-rows-selected {
    .deselect-only-header {
      visibility: hidden;
    }
  }
  &.ag-theme-alpine {
    .ag-cell-focus.cell-no-focus {
      border-color: transparent !important;
    }
  }
  .deselect-only-header {
    justify-content: center;
    margin-left: 2px;
  }
  .checkbox-cell {
    .ag-checkbox-input:not(:disabled) {
      cursor: pointer;
    }
    .ag-cell-value {
      display: none;
    }
    .ag-cell-wrapper {
      justify-content: center;
      .ag-selection-checkbox {
        margin-right: -2px;
      }
    }
  }
  .ag-cell.select-cell {
    padding: 0;
    background: rgba(0, 123, 139, 0.05);
    position: relative;
    .action-options-select {
      display: flex;
      align-items: center;
      .option-initial,
      .option-selected {
        color: #007b8b;
        font-weight: bold;
      }
      &.has-new-value {
        background: rgba(0, 123, 139, 0.2);
        option {
          font-style: normal;
        }
        .option-initial {
          font-weight: normal;
          color: #618aaa;
        }
        .cancel-btn {
          display: inline-block;
        }
        .chevron-icon {
          display: none;
        }
      }
      select {
        padding: 0 17px;
        height: 40px;
        background: rgba(255, 255, 255, 0.001);
        option {
          background: rgba(255, 255, 255, 0.001);
        }
      }
    }
    .cancel-btn {
      color: #9d5454;
      width: 18px;
      margin-right: 4px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      transition: opacity 0.2s;
      opacity: 0.7;
      &:hover {
        opacity: 1;
      }
      display: none;
    }
    .chevron-icon {
      width: 18px;
      margin-right: 4px;
      transition: opacity 0.2s;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      opacity: 0.6;
      pointer-events: none;
    }
  }
`;

const ViewAL = ({
  viewId: propViewId,
  reportId: propReportId,
  icon: propIcon,
  description: propDescription,
  pageTitle: propPageTitle,
  pathArray: propPathArray,
}) => {
  const { t } = useTranslation("common");
  const { customer, getCustomer } = useContext(AppContext);
  const { mutate } = useSWRConfig();
  const { addToast } = useToast();

  /// State ///

  // Pagination
  const PAGESIZE = 100;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGESIZE);
  const [total, setTotal] = useState(0);
  const [rows, setRows] = useState([]);

  // Actions
  const [action, setAction] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [actionData, setActionData] = useState([]);

  // Filter
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  // Layout
  const [viewFullScreen, setViewFullScreen] = useState(false);
  const ROW_HEIGHT = 42;

  const [eventListener, setEventListener] = useState(false);

  const [checked, setChecked] = useState(false);

  const [search, setSearch] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const resetBtn = createRef();
  /// end State ///

  // Search
  const clearSearch = () => {
    setSearch("");
    setSearchQuery("");
    resetBtn.current.click();
    setPage(1);
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value.toLowerCase());
  };

  // Pagination
  const handleSelectPageSize = (e) => {
    setPage(1);
    setPageSize(e.target.value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.keyCode === 27) {
      clearSearch();
    }
  };

  useEffect(() => {
    // Only trigger search if input is at least 3 characters or empty (to reset)
    if (search.length >= 3 || search.length === 0) {
      const delayDebounceFn = setTimeout(() => {
        setSearchQuery(search);
        setPage(1);
      }, 300); // Adjust debounce delay as needed

      return () => clearTimeout(delayDebounceFn);
    }
  }, [search]);

  // Filter
  const onFilterChanged = ({ colId, selectedItems }) => {
    setSelectedFilters([
      ...selectedFilters.filter((item) => item.colId !== colId),
      {
        colId: colId,
        filters: !!selectedItems.length > 0 ? [selectedItems.at(-1)] : [],
      },
    ]);
    setPage(1);
  };

  // Data
  const { alReportId: paramReportId, alViewId: paramViewId } = useParams();
  const { data: menuReports } = useViews({ chartType: "actionList" });
  const reportId = propReportId ? propReportId : paramReportId;
  const viewId = propViewId ? propViewId : paramViewId;

  const {
    data: view,
    isLoading,
    isError: viewError,
    cacheKey: viewCacheKey,
  } = useView({ reportId, viewId });
  const filterableColumns = !!view
    ? view.charts[0].columns
        .filter((item) => !!item.filter)
        .map((item) => item.id)
    : [];

  const {
    data: viewPage,
    isLoading: viewPageIsLoading,
    isError: viewPageIsError,
    cacheKey: viewPageCacheKey,
  } = useViewPage({
    // Fetch always except initial load of the first page with 100 rows, no search or filters
    // shouldFetch: () =>
    //   (!!view && page > 1) ||
    //   (!!view &&
    //     !!view.chart &&
    //     !!view.chart.rows &&
    //     view.chart.rows.length === 0) ||
    //   !!searchQuery ||
    //   selectedFilters.length > 0 ||
    //   (!!view && !!view.chart && view.chart.pageSize !== pageSize) ||
    //   pageSize !== PAGESIZE,
    reportId: reportId,
    page: page,
    pageSize: pageSize,
    search: searchQuery,
    pageFilters: selectedFilters.filter((item) =>
      filterableColumns.includes(item.colId)
    ),
  });

  const listSearchable = (cols) => {
    const filtered = cols.filter((col) => !!col.searchable);
    return filtered
      .map((col, index) => col.name + (index < filtered.length - 1 ? ", " : ""))
      .join("");
  };

  domPurifyWithHooks(DOMPurify);

  // Get customer info
  useEffect(() => {
    if (!customer) {
      getCustomer();
    }
  }, [customer, getCustomer]);

  // Update rows and totals in state when new data is fetched
  useEffect(() => {
    if (!!viewPage && !!viewPage?.rows) {
      setRows(viewPage.rows.map((item) => [item.id, ...item.data]));
      setTotal(viewPage?.total);
    }
  }, [viewPage]);

  // Save picked up data in state
  useEffect(() => {
    if (
      !!view &&
      !!view.chart &&
      page === 1 &&
      pageSize === 100 &&
      selectedFilters.length === 0
    ) {
      if (!!view.chart.rows.length) {
        // setRows(view.chart.rows.map((item) => [item.id, ...item.data]));
        setActionData([]);
      }
      if (!!view.chart.total) {
        setTotal(view.chart.total);
      }
      if (!!view.chart.actions.length) {
        setAction(view.chart.actions[0]);
      } else {
        setAction(null);
      }
    }
  }, [view, reportId, viewId, page, pageSize, selectedFilters]);

  const refreshReport = () => {
    mutate(viewCacheKey);
    mutate(viewPageCacheKey);
  };

  // export
  const gridRef = useRef();
  const [isDownloading, setIsDownloading] = useState(false);

  const saveFile = ({ exportType, fileName, data }) => {
    if (exportType === "excel") {
      saveAs(data, fileName);
    } else if (exportType === "csv") {
      const blob = makeBlobFromCSV(data?.data, { decodeURI: true });
      saveAs(blob, fileName);
    } else if (exportType === "csvPage") {
      const blob = makeBlobFromCSV(data, { decodeURI: false });
      saveAs(blob, fileName);
    }
  };

  const exportCurrentPageCSV = useCallback(() => {
    try {
      const csvData = gridRef?.current?.api.getDataAsCsv();
      const fileName = `${view.name} - ${customer?.name || ""}${
        !!total
          ? ` - ${pageSize * (page - 1) + 1} - ${
              total > pageSize * page ? pageSize * page : total
            }`
          : " - data set"
      }.csv`;
      saveFile({
        exportType: "csvPage",
        fileName: fileName,
        data: csvData,
      });
      addToast(
        ({ close }) => (
          <Notification
            title={t("ui.Exporting")}
            text={t("ui.File-will-be-saved-in-your-downloads-folder")}
            errorText={fileName}
            type="success"
            handleClose={close}
          />
        ),
        { placement: "bottom" }
      );
    } catch (error) {
      console.log(error);
      addToast(
        ({ close }) => (
          <Notification
            title={t("error.Could-not-download-file")}
            text={t("error.Could-not-download-file-description")}
            type="error"
            handleClose={close}
          />
        ),
        { placement: "bottom" }
      );
    }
    setIsDownloading(false);
  }, [page, pageSize, total, view?.name, customer?.name, addToast, t]);

  const downloadFile = (exportType) => {
    setIsDownloading(true);
    exportType === "csvPage"
      ? exportCurrentPageCSV()
      : callViewExportFile({
          reportId: reportId,
          fileType: exportType,
          onSuccess: (data) => {
            setIsDownloading(false);
            const ext = exportType === "excel" ? ".xlsx" : ".csv";
            const fileName = `${view.name} - ${customer?.name}${ext}`;
            saveFile({
              exportType: exportType,
              fileName: fileName,
              data: data,
            });
            addToast(
              ({ close }) => (
                <Notification
                  title={t("ui.Exporting")}
                  text={t("ui.File-will-be-saved-in-your-downloads-folder")}
                  errorText={fileName}
                  type="success"
                  handleClose={close}
                />
              ),
              { placement: "bottom" }
            );
          },
          onError: () => {
            setIsDownloading(false);
            addToast(
              ({ close }) => (
                <Notification
                  title={t("error.Could-not-download-file")}
                  text={t("error.Could-not-download-file-description")}
                  type="error"
                  handleClose={close}
                />
              ),
              { placement: "bottom" }
            );
          },
        });
  };

  const onRowSelected = ({ node, data }) => {
    const nodeRowId = data?.[0];
    const actionDataRow = actionData.filter(
      (row) => row.rowId === nodeRowId
    )?.[0];
    if (node.isSelected()) {
      // is selected
      if (!actionDataRow) {
        // has no data
        setActionData((curr) => [
          ...curr,
          {
            rowId: nodeRowId,
            optionKey: "",
          },
        ]);
      }
    } else {
      // is deselected
      if (!!actionDataRow) {
        // still has data
        setActionData((curr) => curr.filter((row) => row.rowId !== nodeRowId));
      }
    }
  };

  // On row data changed
  const onRowDataChanged = (params) => {
    // setLoaded(true);
    setActionData([]);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (eventListener) {
        const message = t("ui.sure-to-leave");
        event.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [eventListener, t]);

  const applyAction = () => {
    setEventListener(true);
    const batchSize = 50;
    let index = 0;
    if (actionData.length > 50) {
      addToast(({ close }) => (
        <Notification
          title={t("ui.actions-processed")}
          text={t("ui.please-wait")}
          type="success"
          handleClose={close}
        />
      ));
    }
    setShowLoader(true);

    const processBatch = () => {
      if (index < actionData.length) {
        const batch = actionData.slice(index, index + batchSize);
        index += batchSize;

        callAction({
          reportId,
          actionId: action.id,
          rowData: batch,
          onSuccess: () => {
            setTimeout(() => {
              processBatch();
            }, 1000);
          },
          onError: (error) => {
            console.error(`Failed to apply action for batch: ${error}`);
            addToast(({ close }) => (
              <Notification
                title={t("error.Something-went-wrong")}
                text={
                  t("error.Sorry-an-unexpected-error-occurred") + " " + error
                }
                type="error"
                handleClose={close}
              />
            ));
            setTimeout(() => {
              processBatch();
            }, 1000);
          },
        });
      } else {
        refreshReport();
        setActionData([]);
        gridRef?.current?.api.deselectAll();
        setEventListener(false);
        setShowLoader(false);
        setChecked(false);
      }
    };
    processBatch(); // Start the batch processing
  };

  const selectAllActionData = () => {
    gridRef?.current?.api.selectAll();
    const allData = rows.map((row) => ({ rowId: row[0], optionKey: "" }));
    setActionData(allData);
  };

  const resetActionData = () => {
    gridRef?.current?.api.deselectAll();
    setActionData([]);
  };

  // Title
  const getTitle = () =>
    menuReports &&
    !!menuReports.length &&
    !!menuReports.filter(
      (item) => item.id === +viewId && item.reportId === +reportId
    ).length
      ? menuReports.filter(
          (item) => item.id === +viewId && item.reportId === +reportId
        )[0]?.name
      : "Action-list " + reportId;

  // Html description
  domPurifyWithHooks(DOMPurify);
  const descriptionWithLineBreaks =
    propDescription?.replaceAll("\n", "<br/>") ||
    view?.description?.replaceAll("\n", "<br/>") ||
    "";

  const titleActionlists =
    config.clientCode === VOLVO
      ? t("menu-Volvo.Action-lists")
      : t("menu.Action-lists");

  // Breadcrumbs
  const pathFromStr = !!view?.path
    ? (view.path.indexOf("/") === 0
        ? view.path.substring(1).split("/")
        : view.path.split("/")
      )
        .filter((item) => !!item)
        .map((item) => ({
          title: item,
        }))
    : [];

  const bcPath = propPathArray
    ? propPathArray
    : [
        {
          title: titleActionlists,
          url: "/action-lists",
        },
        ...pathFromStr,
      ];

  return (
    <Layout
      main={
        <GridContext.Provider
          value={{ actionData: actionData, setActionData: setActionData }}
        >
          {isLoading ? (
            <Card
              title={"..."}
              icon={propIcon ? propIcon : icons.actions}
              shadow
            >
              <Loader />
            </Card>
          ) : viewError ? (
            <Card
              title={propPageTitle ? propPageTitle : getTitle()}
              icon={propIcon ? propIcon : icons.actions}
              shadow
              className="main-card"
            >
              <div
                className="border-bottom"
                style={{ paddingBottom: "1em", marginBottom: "1em" }}
              >
                <Breadcrumbs
                  pageTitle={propPageTitle ? propPageTitle : getTitle()}
                  pathArray={bcPath}
                  noMargin
                />
              </div>

              <Error
                errorDetailsStr={viewError?.message ? viewError.message : null}
                errorObj={viewError}
              />
            </Card>
          ) : (
            <Card
              title={propPageTitle ? propPageTitle : view.name}
              icon={propIcon ? propIcon : icons.actions}
              shadow
              noRadius={!!viewFullScreen}
              stretchFullScreen={!!viewFullScreen}
              noMargin={!!viewFullScreen}
              noPadding={!!viewFullScreen}
              className={"main-card al-card"}
              headerRight={
                <Button
                  secondary={!viewFullScreen}
                  primary={!!viewFullScreen}
                  small
                  onClick={() =>
                    setViewFullScreen((curr) => {
                      if (!curr) {
                        document
                          .getElementsByTagName("body")?.[0]
                          ?.classList.add("overflow-hidden");
                        document
                          .getElementById("static-container")
                          .classList.add("fullscreen");
                      } else {
                        document
                          .getElementsByTagName("body")?.[0]
                          ?.classList.remove("overflow-hidden");
                        document
                          .getElementById("static-container")
                          .classList.remove("fullscreen");
                      }
                      return !curr;
                    })
                  }
                  title={
                    !viewFullScreen
                      ? t("ui.View-fullscreen")
                      : t("ui.Exit-fullscreen-view")
                  }
                >
                  {!!viewFullScreen ? (
                    <AiOutlineFullscreenExit />
                  ) : (
                    <AiOutlineFullscreen />
                  )}
                </Button>
              }
            >
              {viewFullScreen ? null : (
                <div
                  className="border-bottom"
                  style={{ paddingBottom: "1em", marginBottom: "1em" }}
                >
                  <Breadcrumbs
                    pageTitle={propPageTitle ? propPageTitle : view.name}
                    pathArray={bcPath}
                    noMargin
                  />
                </div>
              )}

              {viewFullScreen ? null : propDescription ? (
                <div className="margin-bottom">{propDescription}</div>
              ) : (
                !!view.description && (
                  <div
                    className="margin-bottom"
                    dangerouslySetInnerHTML={
                      !!view &&
                      !!view.description && {
                        __html: DOMPurify.sanitize(descriptionWithLineBreaks, {
                          USE_PROFILES: { html: true },
                        }),
                      }
                    }
                  />
                )
              )}

              <SToolbarTop className="toolbar-top" fullScreen={viewFullScreen}>
                <div className="row-wrap">
                  <Row
                    style={{
                      justifyContent: !!view?.chart?.actions?.length
                        ? "space-between"
                        : "flex-end",
                    }}
                  >
                    {!!view?.chart?.actions?.length && !!action ? (
                      <Col sm="auto" flexGrow={1}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                          className="toolbar-item"
                        >
                          <div style={{ marginRight: ".8em", flexGrow: 0 }}>
                            <Dropdown
                              selectedItem={action}
                              onSelect={setAction}
                              downshiftProps={{
                                itemToString: (dropdownItem) =>
                                  dropdownItem && dropdownItem.name,
                              }}
                            >
                              <Trigger>
                                <span>
                                  <Button link title={t("ui.Action")}>
                                    {action.name}
                                    <BiChevronDown />
                                  </Button>
                                </span>
                              </Trigger>

                              <Menu style={{ minWidth: "280px" }}>
                                {view?.charts?.[0]?.actions.map(
                                  (item, index) => (
                                    <Item
                                      key={index}
                                      className="dropdown-item"
                                      value={item}
                                    >
                                      {item.name}
                                      {!!item.description ? (
                                        <span
                                          style={{
                                            display: "block",
                                            fontSize: "80%",
                                            opacity: ".7",
                                          }}
                                        >
                                          {item.description}
                                        </span>
                                      ) : null}
                                    </Item>
                                  )
                                )}
                              </Menu>
                            </Dropdown>
                          </div>

                          <Button
                            small
                            $success
                            onClick={applyAction}
                            disabled={!actionData?.length}
                          >
                            <IoCheckmarkSharp />
                            <span style={{ whiteSpace: "nowrap" }}>
                              {t("ui.Apply")} ({actionData?.length})
                            </span>
                          </Button>
                        </div>
                      </Col>
                    ) : null}

                    {!!view?.chart?.columns?.length ? (
                      <Col sm="auto">
                        <div className="toolbar-item">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {view.chart.columns.some(
                              (col) => !!col.searchable
                            ) ? (
                              <form
                                style={{
                                  marginBottom: "1em",
                                  marginRight: "0.5em",
                                }}
                              >
                                <FormField>
                                  <Label hidden>{t("ui.Search")}</Label>
                                  <MediaInput
                                    isCompact
                                    start={<StartIcon />}
                                    end={
                                      !!search.length && (
                                        <span onClick={clearSearch}>
                                          <EndIcon className="cursor-pointer" />
                                        </span>
                                      )
                                    }
                                    placeholder={listSearchable(
                                      view.chart.columns
                                    )}
                                    onChange={handleSearchInput}
                                    onKeyDown={handleSearchKeyDown}
                                    defaultValue={!!search ? search : ""}
                                    value={search}
                                    disabled={
                                      !view.chart.columns.some(
                                        (col) => !!col.searchable
                                      )
                                    }
                                  />
                                </FormField>
                                <input type="reset" ref={resetBtn} hidden />
                                <input type="reset" hidden />
                              </form>
                            ) : null}
                            {/* Filter button */}
                            {view.charts[0].columns.filter(
                              (col) => !!col.filter && col.filter.length > 0
                            )?.length ? (
                              <Button
                                small
                                secondary
                                title={"Filter"}
                                style={{
                                  marginRight: ".4em",
                                  width: "34px",
                                  paddingRight: "2px",
                                  paddingLeft: "2px",
                                }}
                                onClick={(e) => setShowFilters((curr) => !curr)}
                                active={showFilters}
                              >
                                <RiFilterFill />
                              </Button>
                            ) : null}

                            {/* Export button */}
                            <Dropdown onSelect={(item) => downloadFile(item)}>
                              <Trigger>
                                <span>
                                  <Button
                                    small
                                    secondary
                                    title={
                                      isDownloading
                                        ? t("ui.Wait-for-download")
                                        : t("ui.Export")
                                    }
                                    disabled={isDownloading}
                                  >
                                    {isDownloading ? (
                                      <BiLoader className="spin" />
                                    ) : (
                                      <BiDownload />
                                    )}
                                    <span>{t("ui.Export")}</span>
                                    <BiChevronDown />
                                  </Button>
                                </span>
                              </Trigger>
                              <Menu
                                hasArrow
                                placement="bottom-end"
                                style={{ minWidth: "280px" }}
                              >
                                <Item value="csvPage" className="dropdown-item">
                                  <span className="dropdown-menu-icon">
                                    <FaFileCsv />
                                  </span>
                                  {t("ui.Current-page-to")} CSV
                                  <ItemMeta className="dropdown-item-with-icon-meta">
                                    {!!total
                                      ? `${t("ui.Rows")} ${
                                          pageSize * (page - 1) + 1
                                        } - ${
                                          total > pageSize * page
                                            ? pageSize * page
                                            : total
                                        }`
                                      : t("ui.Currently-visible-rows")}
                                  </ItemMeta>
                                </Item>
                                <Separator />
                                <Item
                                  value="csv"
                                  className="dropdown-item"
                                  disabled={total > 10000}
                                >
                                  <span className="dropdown-menu-icon">
                                    <FaFileCsv />
                                  </span>
                                  {t("ui.Full-export-to")} CSV
                                </Item>
                                <Item
                                  value="excel"
                                  className="dropdown-item"
                                  // disabled={total > 10000}
                                >
                                  <span className="dropdown-menu-icon">
                                    <FaFileExcel />
                                  </span>
                                  {t("ui.Full-export-to")} Excel
                                </Item>
                              </Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </Col>
                    ) : null}
                  </Row>

                  <Row>
                    {/* Filters */}
                    {view.charts[0].columns
                      .filter((col) => !!col.filter && col.filter.length > 0)
                      .map((col) => (
                        <Col
                          sm={4}
                          key={col.id}
                          style={{
                            height: showFilters ? "40px" : 0,
                            transition: "height .2s",
                          }}
                        >
                          <div className="toolbar-item">
                            <MultiSelectFilter
                              options={col.filter}
                              name={col.name}
                              colId={col.id}
                              colFilters={
                                selectedFilters.filter(
                                  (item) => item.colId === col.id
                                ).length > 0
                                  ? selectedFilters.filter(
                                      (item) => item.colId === col.id
                                    )[0].filters
                                  : []
                              }
                              selectedFilters={selectedFilters}
                              onFilterChanged={onFilterChanged}
                              disabled={!!actionData.length}
                              checked={checked}
                              setChecked={setChecked}
                              isCompact
                            />
                          </div>
                        </Col>
                      ))}
                  </Row>
                </div>
              </SToolbarTop>

              <SGridWrap
                className={`ag-theme-alpine grid-container ${
                  viewFullScreen ? "grid-container-fullscreen" : ""
                } ${!actionData?.length ? "no-rows-selected" : ""}`}
                style={
                  rows.length
                    ? {
                        height: `calc( ${
                          rows.length * ROW_HEIGHT
                        }px + 51px + 17px )`,
                      }
                    : {}
                }
              >
                <AgGridReact
                  rowData={rows}
                  columnTypes={{
                    checkboxColumn: {
                      headerName: "Row ID",
                      headerComponent: SelectDeselectAllHeader,

                      headerComponentParams: {
                        resetActionData: resetActionData,
                        selectAllActionData: selectAllActionData,
                        checked: checked,
                        setChecked: setChecked,
                      },
                      headerClass: "select-deselect-header",
                      checkboxSelection: !!view?.chart?.actions?.length,
                      sortable: false,
                      resizable: false,
                      lockPosition: "left",
                      width: 39,
                      maxWidth: 39,
                      suppressSizeToFit: true,
                      suppressAutoSize: true,
                      cellClass: "checkbox-cell cell-no-focus",
                      suppressCellFocus: true,
                      valueFormatter: () => " ",
                      autoHeight: false,
                      flex: 0,
                    },
                    actionOptionsColumn: {
                      cellRenderer: ActionCellRenderer,
                      cellRendererParams: (params) => ({
                        values: sortKeysByValue(params.colDef.refData),
                      }),
                      toString: true,
                      width: 250,
                      minWidth: 250,
                      cellClass: "select-cell",
                      autoHeight: false,
                    },
                  }}
                  columnDefs={[
                    {
                      field: "ID", // Row ID column
                    },
                    ...view?.chart?.columns,
                  ]?.map(({ name, actionOptions, dataType }, i) => ({
                    field: i + "",
                    headerName: name,
                    type:
                      i === 0
                        ? "checkboxColumn"
                        : !!actionOptions
                        ? "actionOptionsColumn"
                        : null,
                    refData: actionOptions ? actionOptions : null,
                    valueFormatter: ({ value }) =>
                      dataType === "boolean"
                        ? t(value === 0 ? "my-account.No" : "my-account.Yes")
                        : dataType === "date" && !!value
                        ? formatDate(value)
                        : dataType === "dateTime" && !!value
                        ? formatDateTime(value)
                        : value,
                    flex:
                      i === view?.chart?.columns?.length ||
                      view?.chart?.columns?.length <= 3
                        ? 1
                        : 0,
                    minWidth: i === view?.chart?.columns?.length ? 150 : 0,
                  }))}
                  defaultColDef={{
                    sortable: true,
                    resizable: true,
                    width: 150,
                    cellRenderer: HTMLCellRenderer,
                    wrapText: false,
                    autoHeight: true,
                  }}
                  rowSelection="multiple"
                  onRowSelected={onRowSelected}
                  onRowDataChanged={onRowDataChanged}
                  onFirstDataRendered={(params) => {
                    setTimeout(() => {
                      const gridContainer =
                        document.querySelector(".grid-container");
                      const containerHeight = gridContainer?.clientHeight;
                      const actualHeight = document.querySelector(
                        ".ag-center-cols-container"
                      ).clientHeight;
                      gridContainer.classList.add("grid-data-loaded");
                      if (actualHeight > containerHeight) {
                        gridContainer.setAttribute(
                          "style",
                          `height: ${actualHeight}px`
                        );
                      }
                    }, 1);
                  }}
                  suppressRowClickSelection
                  ref={gridRef}
                  enableCellChangeFlash={true}
                  animateRows={true}
                  localeText={{ noRowsToShow: t("ui.No-rows-to-display") }}
                >
                  {page > 1 && viewPageIsLoading ? <Loader /> : null}
                  {page > 1 && viewPageIsError ? (
                    <Error errorObj={viewPageIsError} />
                  ) : null}
                </AgGridReact>
                {showLoader || viewPageIsLoading ? <LoaderOverlay /> : null}
              </SGridWrap>

              <div
                className="row-wrap"
                style={{ paddingTop: viewFullScreen ? 0 : "4px" }}
              >
                <Row alignItems="center" style={{ minHeight: "38px" }}>
                  <Col sm={4}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: viewFullScreen ? "2px" : "0",
                      }}
                    >
                      <FormField
                        style={{
                          minHeight: "30px",
                        }}
                      >
                        <FormSelect
                          isCompact
                          value={pageSize}
                          onChange={handleSelectPageSize}
                          className="select-bg"
                        >
                          {[10, 20, 50, 100, 500, 1000, 2000].map((size) => (
                            <option key={size} value={size}>
                              {size}
                            </option>
                          ))}
                        </FormSelect>
                      </FormField>
                      <Label
                        style={{
                          whiteSpace: "nowrap",
                          fontWeight: "normal",
                          marginLeft: ".7em",
                        }}
                      >
                        {t("ui.per-page")}
                      </Label>
                    </div>
                  </Col>
                  <Col sm={4}>
                    {total > pageSize ? (
                      <Pagination
                        totalPages={Math.ceil(total / pageSize)}
                        pagePadding={0}
                        currentPage={page}
                        onChange={setPage}
                        style={{
                          opacity: !!actionData.length ? ".5" : "1",
                          pointerEvents: !!actionData.length ? "none" : "unset",
                        }}
                      />
                    ) : null}
                  </Col>
                  <Col sm={4} className="text-right">
                    {!!total ? (
                      <p style={viewFullScreen ? { padding: "10px" } : {}}>
                        {pageSize * (page - 1) + 1} -{" "}
                        {total > pageSize * page ? pageSize * page : total}{" "}
                        {t("ui.out-of")} {total}
                      </p>
                    ) : (
                      <p
                        style={
                          viewFullScreen
                            ? { padding: "0 10px" }
                            : { paddingTop: "1.5em" }
                        }
                      >
                        {" "}
                      </p>
                    )}
                  </Col>
                </Row>
              </div>
            </Card>
          )}
        </GridContext.Provider>
      }
      pageTitle={!!view ? view.name : "..."}
      showFooter={false}
      fullScreen={viewFullScreen}
    />
  );
};

export default ViewAL;
